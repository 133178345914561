label > input[type='checkbox'] + span svg {
  display: none;
}

label > input[type='checkbox']:checked + span {
  background-color: #2c2c2c;
  border-color: #2c2c2c;
}

label > input[type='checkbox']:checked + span svg {
  display: block;
  fill: #fff;
}

label > input[type='checkbox']:disabled:checked + span {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}
