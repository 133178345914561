label > input[type='radio'] {
  display: none;
}

label.radio-normal > input[type='radio'] + *::before {
  content: '';
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  margin-right: 0.625rem;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.125rem;
  border-color: #333;
}

label.radio-normal > span {
  color: #333;
  cursor: pointer;
}

label.radio-normal > input[type='radio']:checked + * {
  color: #333;
}

label.radio-normal > input[type='radio']:disabled + *::before {
  border-color: #bdbdbd;
}

label.radio-normal > input[type='radio']:disabled + span {
  color: #bdbdbd;
  cursor: default;
}

label.radio-normal > input[type='radio']:checked + *::before {
  background: radial-gradient(#333 0%, #333 40%, transparent 50%, transparent);
  border-color: #333;
}

label.radio-normal > input[type='radio']:checked:disabled + *::before {
  background: radial-gradient(#bdbdbd 0%, #bdbdbd 40%, transparent 50%, transparent);
  border-color: #bdbdbd;
}

label.radio-device > span {
  position: absolute;
  top: 0;
  left: 0;
  width: 6.125rem;
  height: 6.125rem;
}

.nvidia label.radio-device > input[type='radio']:checked + span {
  background: #85b737;
  color: #fff;
}

.raspberry label.radio-device > input[type='radio']:checked + span {
  background: #8b2543;
  color: #fff;
}

/* @layer components {
  .deviceRadio {
    @apply inline-flex flex-col items-center text-center pt-6 border-2 bg-white shadow-nota;
  }

  .nvidia .deviceRadio {
    @apply border-nvidia text-nvidia;
  }

  .raspberry .deviceRadio {
    @apply border-raspberryPi text-raspberryPi;
  }
} */
