.loader.circle {
  margin: 50px auto;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}
.loader.circle::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #60a5fa;
  animation: prixClipFix 2s linear infinite;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.loader {
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%;
  grid-gap: 3px;
  width: 48px;
  height: 48px;
}

.loader > div {
  position: relative;
  width: 100%;
  height: 100%;
  background: #1c77c3;
  transform: scale(0);
  transform-origin: center center;
  animation: loader 2s infinite ease-in-out;
}

.loader > div:nth-of-type(1),
.loader > div:nth-of-type(5),
.loader > div:nth-of-type(9) {
  animation-delay: 0.4s;
}

.loader > div:nth-of-type(4),
.loader > div:nth-of-type(8) {
  animation-delay: 0.2s;
}

.loader > div:nth-of-type(2),
.loader > div:nth-of-type(6) {
  animation-delay: 0.6s;
}

.loader > div:nth-of-type(3) {
  animation-delay: 0.8s;
}

@keyframes loader {
  0% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
  80% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
