@import './components/checkbox.css';
@import './components/loader.css';
@import './components/radiobutton.css';
@import './components/select.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-regular.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-medium.otf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-semibold.otf');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../assets/fonts/gilroy-bold.otf');
  font-weight: 700;
  font-style: normal;
}
